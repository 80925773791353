import React from "react";
import { Routes, Route, } from "react-router-dom";
import './main.css';
import HomePage from "./HomePage/homepage.js";
import AboutUs from "./AboutUs/aboutus.js";
import Library from "./Library/library.js";

function Main() {
  return (
    <div className="AppMain">
        <div className="wrapper">
          <Routes>
              <Route  path="/" element={<HomePage />} >
              </Route>
              <Route  path="/Library" element={<Library />} >
              </Route>
              <Route  path="/AboutUs" element={<AboutUs />} >
              </Route>
          </Routes>
        </div>
    </div>
  );
}

export default Main;
