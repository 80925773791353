import React from "react";
import './aboutus.css';
import Photo from '../../../Assest/Img/3.jpg';
import GenSec from "../GenSec/genSection";

function AboutUs() {
  return (
    <div className="AboutUs">
      <GenSec photo={Photo} text={"Մեր մասին"}/>
      <div className="wrapper">
        <h1 className="aboutustitle">«Աբաջյան Շին» ՍՊԸ</h1>
        <span className="hvhh">ՀՎՀՀ 05541165</span>
        <p className="texto">Աբաջյան Շին ՍՊԸ-ն հիմնադրվել է 2014 թ-ի մարտի 4-ին Հիմնադիր և 100% բաժնետեր է հանդիսանում՝ Աբաջյան Աշոտ Վարազդատի։</p>
        <p className="texto">Աբաջյան Շին ՍՊԸ-ն հանդիսանում է շինարարական կազմակերպություն։</p>
        <p className="texto">Աբաջյան Շին ՍՊԸ-ն կատարում է երեք ճյուղի լիցենզավորված շինարարություն ՝</p>
        <p className="texto">- ԲՆԱԿԵԼԻ, ՀԱՍԱՐԱԿԱԿԱՆ և ԱՐՏԱԴՐԱԿԱՆ</p>
        <p className="texto">- ՏՐԱՆՍՊՈՐՏԱՅԻՆ</p>
        <p className="texto">- ՀԻԴՐՈՏԵԽՆԻԿԱԿԱՆ</p>
        <p className="texto">&nbsp;&nbsp;&nbsp;&nbsp;Աբաջյան Շին ՍՊԸ-ի գործընկեր կազմակերպություններ են հանդիսանում ` «ԼՈՒՅՍԵՐ» ՓԲԸ, «ՌԵՆՇԻՆ » ՍՊԸ, «ՀՍԿԱ» ՓԲԸ, «Դ ԵՎ Ս ՔՈՆՍԹՐԱՔՇՆ» ՍՊԸ, 
          «ԷՆԵՐԳՈՍԻՍԹԵՄ» ՍՊԸ, «ՊՐՈՖ ԳԱԶ» ՍՊԸ, «ՌՈՒՎՐ ՌԻԵԼԹԻ» ՍՊԸ, «ԱՐՀՈՎՇԻՆ» ՍՊԸ, «ՄԵՏԱԼ ԳՌՈՒՊ» ՍՊԸ, «ՆՅՈՒ ՔՈՆՍԱԼԹԻՆԳ» ՍՊԸ, «ԱՐԵԳ» ԱԿ, «ՈՒՆԻՎԵՐՍԱԼ»ՍՊԸ, 
          «ՌԵՎԱՆՇՇԻՆ» ՍՊԸ, «ԲԱՍԻՍ» ՍՊԸ, «ԿԱՐԱՊԵՏՅԱՆ ԵՎ ՈՐԴԻ» ՍՊԸ, «ԷՅՉ-ՔՈՆ» ՍՊԸ և շատ հարգառժան այլկազմակերպություններ․․․</p>
        <br/>
        <div className="address_block_for_contact">
          <div className="addr_block">
            <span>
              <svg width="36" height="41" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35.6172 16.6612L18.5526 2.43159C18.2236 2.15724 17.7765 2.15724 17.4476 2.43159L0.382892 16.6612C-0.0339203 17.0089 -0.124483 17.6754 0.180744 18.1501C0.48597 18.6248 1.07132 18.7278 1.48806 18.3804L18 4.6114L34.512 18.3803C34.6785 18.5192 34.872 18.5861 35.0638 18.5861C35.3518 18.5861 35.636 18.4351 35.8193 18.15C36.1246 17.6754 36.034 17.0089 35.6172 16.6612Z" fill="#0e7ecd"/>
                  <path d="M31.0949 18.6216C30.5784 18.6216 30.1595 19.0985 30.1595 19.6869V36.6433H22.6767V27.3874C22.6767 24.4501 20.5785 22.0606 17.9995 22.0606C15.4206 22.0606 13.3224 24.4503 13.3224 27.3874V36.6434H5.83948V19.6869C5.83948 19.0986 5.42063 18.6217 4.90412 18.6217C4.3876 18.6217 3.96875 19.0986 3.96875 19.6869V37.7088C3.96875 38.2971 4.3876 38.774 4.90412 38.774H14.2577C14.7496 38.774 15.1522 38.3412 15.1894 37.7917C15.1917 37.7668 15.1931 37.7396 15.1931 37.7088V27.3875C15.1931 25.6251 16.452 24.1913 17.9995 24.1913C19.547 24.1913 20.806 25.6251 20.806 27.3875V37.7088C20.806 37.7394 20.8074 37.7662 20.8097 37.7908C20.8465 38.3407 21.2492 38.774 21.7414 38.774H31.095C31.6115 38.774 32.0303 38.2971 32.0303 37.7088V19.6869C32.0303 19.0985 31.6115 18.6216 31.0949 18.6216Z" fill="#0e7ecd"/>
              </svg>
            </span>
            <div className="addr_info">
                 <p className="addr_title">Հասցե</p>
                <address>Տիչինայի փող., 52 շենք, Երևան, Հայաստան</address>
              </div>
            </div>
            <div className="addr_block">
                <span>
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.1367 18.1845L0.000228882 25.857V10.188L12.1367 18.1845Z" fill="#0e7ecd"/>
                    <path d="M36 10.188V25.857L23.8635 18.1845L36 10.188Z" fill="#0e7ecd"/>
                    <path d="M2.25109 5.625H33.7511C34.8738 5.625 35.7648 6.462 35.9336 7.53975L18.0011 19.3545L0.0685883 7.53975C0.237339 6.462 1.12834 5.625 2.25109 5.625Z" fill="#0e7ecd"/>
                    <path d="M14.1972 19.5435L17.381 21.6405C17.57 21.7643 17.7837 21.825 17.9997 21.825C18.2157 21.825 18.4295 21.7643 18.6185 21.6405L21.8022 19.5413L35.9277 28.476C35.7545 29.5448 34.868 30.375 33.7497 30.375H2.24973C1.13148 30.375 0.24498 29.5448 0.0717278 28.476L14.1972 19.5435Z" fill="#0e7ecd"/>
                  </svg>
                </span>
                <div className="addr_info">
                  <p className="addr_title">Էլ․ հասցէ</p>
                  <a href="mailto:hakob.abajyan@mail.ru">hakob.abajyan@mail.ru</a>
                </div>
            </div>
            <div className="addr_block">
              <span>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.8349 1.69995H14.1682C13.8554 1.69995 13.6016 1.95382 13.6016 2.26659C13.6016 2.57937 13.8554 2.83324 14.1682 2.83324H19.8349C20.1476 2.83324 20.4015 2.57937 20.4015 2.26659C20.4015 1.95382 20.1476 1.69995 19.8349 1.69995Z" fill="#0e7ecd"/>
                  <path d="M23.7999 1.69995H23.233C22.92 1.69995 22.666 1.95382 22.666 2.26659C22.666 2.57937 22.92 2.83324 23.233 2.83324H23.7999C24.1128 2.83324 24.3668 2.57937 24.3668 2.26659C24.3668 1.95382 24.1128 1.69995 23.7999 1.69995Z" fill="#0e7ecd"/>
                  <path d="M17.7028 28.8999H16.2958C15.4339 28.8999 14.7324 29.6014 14.7324 30.4633V30.737C14.7324 31.5989 15.4339 32.2999 16.2953 32.2999H17.7023C18.5642 32.2999 19.2657 31.5989 19.2657 30.737V30.4633C19.2657 29.6014 18.5642 28.8999 17.7028 28.8999ZM18.1324 30.737C18.1324 30.9739 17.9398 31.1665 17.7029 31.1665H16.2958C16.0584 31.1665 15.8657 30.9739 15.8657 30.737V30.4633C15.8657 30.2259 16.0584 30.0332 16.2958 30.0332H17.7023C17.9398 30.0332 18.1324 30.2258 18.1324 30.4633V30.737Z" fill="#0e7ecd"/>
                  <path d="M24.5859 0H9.41569C8.28632 0 7.36719 0.919129 7.36719 2.0485V31.9515C7.36719 33.0809 8.28632 34 9.41569 34H24.5853C25.7147 34 26.6338 33.0809 26.6338 31.9521V2.0485C26.6339 0.919129 25.7148 0 24.5859 0ZM25.5005 31.9515C25.5005 32.4559 25.0903 32.8666 24.5859 32.8666H9.41569C8.9108 32.8666 8.50054 32.4558 8.50054 31.952V2.0485C8.50054 1.54414 8.9108 1.13336 9.41569 1.13336H24.5853C25.0902 1.13336 25.5005 1.54421 25.5005 2.0485V31.9515H25.5005Z" fill="#0e7ecd"/>
                  <path d="M26.0671 3.3999H7.93383C7.62106 3.3999 7.36719 3.65377 7.36719 3.96655V27.7665C7.36719 28.0793 7.62106 28.3332 7.93383 28.3332H26.0672C26.38 28.3332 26.6338 28.0793 26.6338 27.7665V3.96655C26.6338 3.65377 26.38 3.3999 26.0671 3.3999ZM25.5005 27.1999H8.50048V4.53326H25.5005V27.1999Z" fill="#0e7ecd"/>
                </svg>
              </span>
              <div className="addr_info">
                <p className="addr_title">Հեռ․</p>
                <a href="tel:+37494010445">+37494010445</a>
              </div>
            </div>
            <div className="addr_block">
              <span>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.8349 1.69995H14.1682C13.8554 1.69995 13.6016 1.95382 13.6016 2.26659C13.6016 2.57937 13.8554 2.83324 14.1682 2.83324H19.8349C20.1476 2.83324 20.4015 2.57937 20.4015 2.26659C20.4015 1.95382 20.1476 1.69995 19.8349 1.69995Z" fill="#0e7ecd"/>
                  <path d="M23.7999 1.69995H23.233C22.92 1.69995 22.666 1.95382 22.666 2.26659C22.666 2.57937 22.92 2.83324 23.233 2.83324H23.7999C24.1128 2.83324 24.3668 2.57937 24.3668 2.26659C24.3668 1.95382 24.1128 1.69995 23.7999 1.69995Z" fill="#0e7ecd"/>
                  <path d="M17.7028 28.8999H16.2958C15.4339 28.8999 14.7324 29.6014 14.7324 30.4633V30.737C14.7324 31.5989 15.4339 32.2999 16.2953 32.2999H17.7023C18.5642 32.2999 19.2657 31.5989 19.2657 30.737V30.4633C19.2657 29.6014 18.5642 28.8999 17.7028 28.8999ZM18.1324 30.737C18.1324 30.9739 17.9398 31.1665 17.7029 31.1665H16.2958C16.0584 31.1665 15.8657 30.9739 15.8657 30.737V30.4633C15.8657 30.2259 16.0584 30.0332 16.2958 30.0332H17.7023C17.9398 30.0332 18.1324 30.2258 18.1324 30.4633V30.737Z" fill="#0e7ecd"/>
                  <path d="M24.5859 0H9.41569C8.28632 0 7.36719 0.919129 7.36719 2.0485V31.9515C7.36719 33.0809 8.28632 34 9.41569 34H24.5853C25.7147 34 26.6338 33.0809 26.6338 31.9521V2.0485C26.6339 0.919129 25.7148 0 24.5859 0ZM25.5005 31.9515C25.5005 32.4559 25.0903 32.8666 24.5859 32.8666H9.41569C8.9108 32.8666 8.50054 32.4558 8.50054 31.952V2.0485C8.50054 1.54414 8.9108 1.13336 9.41569 1.13336H24.5853C25.0902 1.13336 25.5005 1.54421 25.5005 2.0485V31.9515H25.5005Z" fill="#0e7ecd"/>
                  <path d="M26.0671 3.3999H7.93383C7.62106 3.3999 7.36719 3.65377 7.36719 3.96655V27.7665C7.36719 28.0793 7.62106 28.3332 7.93383 28.3332H26.0672C26.38 28.3332 26.6338 28.0793 26.6338 27.7665V3.96655C26.6338 3.65377 26.38 3.3999 26.0671 3.3999ZM25.5005 27.1999H8.50048V4.53326H25.5005V27.1999Z" fill="#0e7ecd"/>
                </svg>
              </span>
              <div className="addr_info">
                <p className="addr_title">Հեռ․</p>
                <a href="tel:+37496010445">+37496010445</a>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default AboutUs;