import { BrowserRouter as Router} from "react-router-dom";
import Header from './Components/Header/header';
import Footer from './Components/Footer/footer';
import Main from './Components/Main/main';

function App() {
  return (
    <div className="App">
      <Router path="/">
        <Header/>
        <Main/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
