import React from "react";
import './homepage.css';
import onePhoto from '../../../Assest/Img/1.jpg';
import twoPhoto from '../../../Assest/Img/kolr.png';
import freePhoto from '../../../Assest/Img/ver.jpg';
import GenSec from "../GenSec/genSection";

function HomePage() {
  return (
    <div className="HomaePage">
      <GenSec photo={onePhoto} text={"«ԱԲԱՋՅԱՆ ՇԻՆ» ՍՊԸ"}/>
      <section className="works_about_section">
        <div className="works_about_block">
          <div className="works_about_info_btn_block">
            <h2 className="works_about_info_title">ԱՌԱՋԱՏԱՐԸ Շինարարության ոլորտում</h2>
            <p className="works_about_info_text">«Աբաջյան Շին» ՍՊԸ-ն հիմնադրվել է 2014 թվականին որպես շինարարական ընկերություն։ Աբաջյան Շին ՍՊԸ-ն կատարում է երեք ճյուղի լիցենզավորված շինարարություն ՝ ԲՆԱԿԵԼԻ, ՀԱՍԱՐԱԿԱԿԱՆ և ԱՐՏԱԴՐԱԿԱՆ, ՏՐԱՆՍՊՈՐՏԱՅԻՆ, ՀԻԴՐՈՏԵԽՆԻԿԱԿԱՆ:</p>
          </div>
          <div className="works_about_img_block">
            <img src={freePhoto} alt=""/>
          </div>
        </div>
      </section>
      <section className="support_for_busines_section">
        <div className="support_for_busines_block">
          <div className="support_for_busines_img_blok">
            <div className="black"></div>
            <div className="blue"></div>
            <div className="img_bg">
              <img src={twoPhoto} alt=""/>
            </div>
          </div>
          <div className="support_for_busines_info_block">
            <p className="support_for_busines_info_top">-Մեր ծառայությունները</p>
            <h5 className="support_for_busines_info_title">Վստահեք մեզ Ձեր հաջորդ շինարարական նախագիծը:</h5>
            <div className="support_property_block">
              <p>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="22" height="22" fill="#0e7ecd"/>
                  <path d="M18.6381 2.13892L7.63806 13.1365L3.3621 8.8619L0 12.2239L7.63812 19.861L22 5.50102L18.6381 2.13892Z" fill="white"/>
                </svg>
                Նախաշինարարական պլանավորում</p>
              <p>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="22" height="22" fill="#0e7ecd"/>
                  <path d="M18.6381 2.13892L7.63806 13.1365L3.3621 8.8619L0 12.2239L7.63812 19.861L22 5.50102L18.6381 2.13892Z" fill="white"/>
                </svg>
                Ճարտարապետական մոդելավորում</p>
              <p>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="22" height="22" fill="#0e7ecd"/>
                  <path d="M18.6381 2.13892L7.63806 13.1365L3.3621 8.8619L0 12.2239L7.63812 19.861L22 5.50102L18.6381 2.13892Z" fill="white"/>
                </svg>
                Դրոններով վերահսկողություն</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;