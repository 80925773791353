import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import './header.css';
import Logo from '../../Assest/Img/Logo.png';
import Burger from '../../Assest/Img/burger.png';
import X from '../../Assest/Img/X.png';

function Header() {
    const menuMob = useRef(null);
    const [navbarOpen, setNavbarOpen] = useState(true);
    const [windowSize, setWindowSize] = useState({width: window.innerWidth});

    const openbask = () => {
        setNavbarOpen((prev) => !prev);
    };
    const closebask = () => {
        setNavbarOpen(true);
    };
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
              width: window.innerWidth,
            });
            if(window.innerWidth > 680) {
                setNavbarOpen(true);
            }
        };
        window.addEventListener('resize', handleResize);
        const onClick = e => menuMob.current.contains(e.target) || setNavbarOpen(true);
        document.addEventListener('click', onClick);
        return () => {
            window.removeEventListener('resize', handleResize)
            document.removeEventListener('click', onClick);
          };
      }, []);
  return (
    <div className="AppHeader">
        <div className="wrapper">
            <div className='headerBig' ref={menuMob}>
                <div className="headerBlock">
                    <div className="headerLogo">
                        <Link onClick={closebask} className='menu_link_logo' to="/"><img alt="Logo" src={Logo}/></Link>
                    </div>
                    <div className="menuWeb">
                        <NavLink className='menu_link' to="/">Գլխավոր</NavLink> 
                        <NavLink className='menu_link' to="/Library">Տեսադարան</NavLink>
                        <NavLink className='menu_link' to="/AboutUs">Մեր մասին</NavLink> 
                    </div>
                    <div className="menuMob">
                        <button className='MobMenu' onClick={openbask}>
                            <img src={navbarOpen ? Burger : X} alt="Menu"/>
                        </button>
                    </div>
                </div>
                <div className={navbarOpen ? 'closeMenu' : ' openMenu'}>
                    <NavLink onClick={openbask} className='menu_link' to="/">Գլխավոր</NavLink> 
                    <NavLink onClick={openbask} className='menu_link' to="/Library">Տեսադարան</NavLink>
                    <NavLink onClick={openbask} className='menu_link' to="/AboutUs">Մեր մասին</NavLink> 
                </div>
            </div>
        </div>
    </div>
  );
}

export default Header;
