import React from "react";
import './library.css';
import Photo from '../../../Assest/Img/2.jpg';
import nkar from '../../../Assest/Img/1 (2).jpg';
import nkar1 from '../../../Assest/Img/1 (3).jpg';
import nkar2 from '../../../Assest/Img/1 (4).jpg';
import nkar3 from '../../../Assest/Img/1 (5).jpg';
import nkar4 from '../../../Assest/Img/1 (6).jpg';
import nkar5 from '../../../Assest/Img/1 (7).jpg';
import nkar6 from '../../../Assest/Img/1 (8).jpg';
import nkar7 from '../../../Assest/Img/1 (9).jpg';
import nkar8 from '../../../Assest/Img/1 (10).jpg';
import nkar9 from '../../../Assest/Img/1 (11).jpg';
import nkar10 from '../../../Assest/Img/1 (12).jpg';
import nkar11 from '../../../Assest/Img/1 (13).jpg';
import nkar12 from '../../../Assest/Img/1 (14).jpg';
import nkar13 from '../../../Assest/Img/1 (15).jpg';
import nkar14 from '../../../Assest/Img/1 (16).jpg';
import nkar15 from '../../../Assest/Img/1 (17).jpg';
import nkar16 from '../../../Assest/Img/1 (18).jpg';
import nkar17 from '../../../Assest/Img/1 (18).jpg';
import GenSec from "../GenSec/genSection";
import ModalImage from "react-modal-image";

function Library() {
  return (
    <div className="Library">
      <GenSec photo={Photo} text={"Տեսադարան"}/>
      <div className="libraryPhoto">
        <ModalImage className="ModalImage" small={nkar} large={nkar} />
        <ModalImage className="ModalImage" small={nkar1} large={nkar1} />
        <ModalImage className="ModalImage" small={nkar2} large={nkar2} />
        <ModalImage className="ModalImage" small={nkar3} large={nkar3} />
        <ModalImage className="ModalImage" small={nkar4} large={nkar4} />
        <ModalImage className="ModalImage" small={nkar5} large={nkar5} />
        <ModalImage className="ModalImage" small={nkar6} large={nkar6} />
        <ModalImage className="ModalImage" small={nkar7} large={nkar7} />
        <ModalImage className="ModalImage" small={nkar8} large={nkar8} />
        <ModalImage className="ModalImage" small={nkar9} large={nkar9} />
        <ModalImage className="ModalImage" small={nkar10} large={nkar10} />
        <ModalImage className="ModalImage" small={nkar11} large={nkar11} />
        <ModalImage className="ModalImage" small={nkar12} large={nkar12} />
        <ModalImage className="ModalImage" small={nkar13} large={nkar13} />
        <ModalImage className="ModalImage" small={nkar14} large={nkar14} />
        <ModalImage className="ModalImage" small={nkar15} large={nkar15} />
        <ModalImage className="ModalImage" small={nkar16} large={nkar16} />
        <ModalImage className="ModalImage" small={nkar17} large={nkar17} />
      </div>
    </div>
  );
}

export default Library;