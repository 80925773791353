import React from "react";
import './gensec.css';

function GenSec({photo, text}) {
  return (
      <section className="gensec">
        <img src={photo}/ >
        <div className="page_welcome_info">
          <div className="wrapper">
            <h1 className="general_title">{text}</h1>
          </div>
        </div>
      </section>
  );
}

export default GenSec;